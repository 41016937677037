@import '~katex/dist/katex.min.css';
@import '~react-quill/dist/quill.snow.css';

body {
 width: 100%;
 height: 100%;
}

/* Make a custom scrollbar */
::-webkit-scrollbar {
 height: 8px !important;
 width: 8px !important;
}

::-webkit-scrollbar-thumb {
 background: linear-gradient(transparent, #6a64ff) !important;
 border-radius: 3px !important;
}

::-webkit-scrollbar-thumb:hover {
 background: linear-gradient(transparent, #50c8ff) !important;
}

ion-select {
 max-width: 100% !important;
 white-space: normal !important;
}
.alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
 min-width: 90% !important;
}

ion-select::part(text) {
 white-space: unset !important;
}

.ql-editor img {
 width: 100px;
}
.ql-tooltip.ql-editing {
 z-index: 11;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
 z-index: 11;
}

.ql-container.ql-disabled .ql-tooltip {
 display: none;
}

.ql-editor img {
 max-height: 300px !important;
 width: auto !important;
}

.sc-ion-label-ios-s p {
 overflow: hidden !important;
}

.pane {
 overflow: auto !important;
}

.searchbar-input {
 border-radius: 0.5rem !important;
}
[popover].popover-viewport {
 display: initial;
 position: initial;
 margin: initial;
 border: initial;
 background: initial;
 padding: initial;
 width: initial;
 height: initial;
 overflow: initial;
 inset: initial;
 color: initial;
}
